














































































































































































































































































































































































import { Vue, Prop, Emit, Component, Watch } from "vue-property-decorator";
import DatePicker from "@/components/common/Inputs/DatePicker.vue";
import ImageUpload from "@/components/common/Upload/Image.vue";
import Navbar from "@/components/common/Navbar.vue";
import { CouponDistribution } from "@/Enum/CouponScheme";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  IBasicInfo,
  ISlabs,
  IGifts,
  ICouponDistribution,
} from "@/interfaces/Coupon";
import Snackbar from "@/components/common/Snackbar.vue";
import CouponManagementService from "@/services/CouponManagementService";
import { set } from "vue/types/umd";

library.add(faTrashCan);
@Component({
  components: { DatePicker, ImageUpload, Snackbar, Navbar, FontAwesomeIcon },
})
export default class Create extends Vue {
  private todayplusone: string = "";
  private startDate: string = "";
  private endDate: string = "";
  private onsubmit: boolean = false;
  private CouponDistribution: any = [
    { key: "Fixed Number", value: CouponDistribution.FixedNumber },
  ];
  private maxDaysFromStartDate: number = 180;

  private valid: boolean = false;
  private basicInfo: IBasicInfo = {
    Name: "",
    Description: "",
    StartDate: "",
    EndDate: "",
    ERPId: "",
    CouponDistribution: CouponDistribution.FixedNumber,
    TotalNumberCoupons: 0,
    CouponwiseDistribution: [],
    Slabs: [],
    // MultipleCouponsOrder: false,
  };
  private defaultItem: IBasicInfo = {
    Name: "",
    Description: "",
    StartDate: "",
    EndDate: "",
    ERPId: "",
    CouponDistribution: CouponDistribution.FixedNumber,
    TotalNumberCoupons: 0,
    CouponwiseDistribution: Array<ICouponDistribution>(),
    Slabs: Array<ISlabs>(),
  };
  private GiftsAvailable: Array<IGifts> = [
    {
      value: "fridge1",
      key: 0,
    },
    {
      value: "fridge2",
      key: 1,
    },
    {
      value: "fridge3",
      key: 2,
    },
    {
      value: "fridge4",
      key: 3,
    },
  ];
  private SelectedInputMeasure: Array<IGifts> = [...this.GiftsAvailable];
  private items: any = [];
  private Slabs1: any = [];
  private result1: number = 0;
  private creatingOrUpdatingCouponScheme: boolean = false;
  private result2: number = 0;
  private selectedIndex1: number = -1;

  private snackBarMessage: string = "";
  private snackbar: boolean = false;
  private snackbarColor: string = "success";

  @Watch("items")
  calculation(newValue: any) {
    var newval: number = 0;
    //console.log(this.items);
    if (newValue.length > 0) {
      for (let k = 0; k < newValue.length; k++) {
        var no: number = newValue[k].total;
        newval =
          Number.parseInt(newval.toString()) + Number.parseFloat(no.toString());
        //newval += no;
      }
      this.result1 = newval;
    } else this.result1 = 0;
  }

  Adding(val: number) {
    this.result1 += this.items[val].distributionpercentage;
    return this.result1;
  }

  closeSnackbar(value: boolean) {
    this.snackbar = value;
  }
  isNumber(evt: KeyboardEvent): void {
    const keysAllowed: string[] = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = evt.key;

    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    }
  }

  get formValidate(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }
  get formReset(): Vue & { reset: () => void } {
    return this.$refs.form as Vue & { reset: () => void };
  }
  get formResetValidation(): Vue & { resetValidation: () => void } {
    return this.$refs.form as Vue & { resetValidation: () => void };
  }
  public ValidateForm() {
    var startDate = Date.parse(this.basicInfo.StartDate);
    var endDate = Date.parse(this.basicInfo.EndDate);

    if (startDate > endDate) {
      this.snackBarMessage = "StartDate can't be greater than EndDate.";
      this.onsubmit = false;
      this.snackbar = true;
      return false;
    }
    if (this.basicInfo.TotalNumberCoupons != this.result1) {
      this.snackBarMessage =
        "Coupons number is not equal to total Coupon Number.";
      this.onsubmit = false;
      this.snackbar = true;
      return false;
    }
    if (this.basicInfo.TotalNumberCoupons <= 0) {
      this.snackBarMessage = "Add Gifts to create Scheme.";
      this.onsubmit = false;
      this.snackbar = true;
      return false;
    }

    return true;
  }

  get maxEndDate(): string {
    if (this.basicInfo.StartDate != "" && this.basicInfo.EndDate != "") {
      var date = new Date(this.basicInfo.StartDate);
      date.setDate(date.getDate() + this.maxDaysFromStartDate);
      return date.toISOString().split("T")[0];
    }
    return "";
  }
  AddItem() {
    var a1 = {
      gift: "",
      total: 0,
    };
    this.items.push(a1);
  }
  removeItem(index: number) {
    this.items.splice(index, 1);
  }
  AddSlab() {
    this.Slabs1.push({});
  }
  removeSlab(index: number) {
    this.Slabs1.splice(index, 1);
  }
  public clearForm(): void {
    this.basicInfo = Object.assign({}, this.defaultItem);
    this.items = [];
    this.Slabs1 = [];
  }
  public async refreshCouponList() {
    await this.getCoupon();
  }
  created() {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    this.todayplusone = date.toISOString().split("T")[0];
    //this.basicInfo.StartDate = this.todayplusone;
    date.setDate(date.getDate() + 6);
    //this.basicInfo.EndDate = date.toISOString().split("T")[0];
    this.getCoupon();
  }
  public isNextButtonDisabled(): any {
    if (
      this.basicInfo.TotalNumberCoupons <= 0 ||
      this.valid == false ||
      !this.ValidateForm
    )
      return true;
    else if (this.basicInfo.TotalNumberCoupons > 0) {
      if (this.basicInfo.TotalNumberCoupons != this.result1) return true;
    }
    return false;
  }
  private createOrUpdateCoupon(): void {
    this.onsubmit = true;
    if (this.valid && this.ValidateForm()) {
      var mySet = new Set();
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].gift == undefined || this.items[i].gift == "") {
          this.basicInfo.CouponwiseDistribution = [];
          this.basicInfo.Slabs = [];
          this.onsubmit = false;
          this.snackBarMessage = "Please fill form Correctly";
          this.snackbar = true;
          return;
        }

        if (mySet.has(this.items[i].gift)) {
          this.basicInfo.CouponwiseDistribution = [];
          this.basicInfo.Slabs = [];
          this.snackBarMessage = "Duplicate Coupon selected";
          this.onsubmit = false;
          this.snackbar = true;
          return;
        }
        mySet.add(this.items[i].gift);
        var val = this.GiftsAvailable.find((a) => a.key == this.items[i].gift)
          ?.value;
        if (
          val == undefined ||
          this.items[i].total == NaN ||
          this.items[i].total == 0
        ) {
          this.basicInfo.CouponwiseDistribution = [];
          this.basicInfo.Slabs = [];
          this.snackBarMessage = "Please fill form Correctly";
          this.onsubmit = false;
          this.snackbar = true;
          return;
        }
        var a = {
          GiftId: this.items[i].gift,
          DistributionNumber: this.items[i].total,
          GiftName: val,
        };
        this.basicInfo.CouponwiseDistribution.push(a);
      }
      if (this.Slabs1.length < 1) {
        this.basicInfo.CouponwiseDistribution = [];
        this.basicInfo.Slabs = [];
        this.snackBarMessage = "Please create atleast one Slab";
        this.onsubmit = false;
        this.snackbar = true;
        return;
      }
      var prev1: number = 0;
      var prev2: number = 0;
      for (let i = 0; i < this.Slabs1.length; i++) {
        if (
          this.Slabs1[i].Payout <= 0 ||
          this.Slabs1[i].Constraint <= 0 ||
          this.Slabs1[i].Constraint == undefined ||
          this.Slabs1[i].Payout == undefined
        ) {
          this.basicInfo.CouponwiseDistribution = [];
          this.basicInfo.Slabs = [];
          this.snackBarMessage = "Please fill form Correctly";
          this.onsubmit = false;
          this.snackbar = true;
          return;
        }
        var curr: number = +this.Slabs1[i].Constraint;
        var currP: number = +this.Slabs1[i].Payout;
        if (prev1 > curr || prev2 > currP) {
          this.basicInfo.CouponwiseDistribution = [];
          this.basicInfo.Slabs = [];
          this.snackBarMessage =
            "Please enter Slab Constraint Value and Payout greater than previous slab .";
          this.onsubmit = false;
          this.snackbar = true;
          return;
        }
        prev1 = +this.Slabs1[i].Constraint;
        prev2 = +this.Slabs1[i].Payout;
        var b = {
          PayoutDescription: this.Slabs1[i].PayoutDescription,
          Payout: this.Slabs1[i].Payout,
          Constraint: this.Slabs1[i].Constraint,
        };
        this.basicInfo.Slabs.push(b);
      }
      this.createNewCouponScheme();
    } else {
      this.basicInfo.CouponwiseDistribution = [];
      this.basicInfo.Slabs = [];
      this.snackBarMessage = "Data Missing";
      this.onsubmit = false;
      this.snackbar = true;
      return;
    }
  }
  public createNewCouponScheme(): void {
    let value = Object.assign({}, this.basicInfo);

    this.creatingOrUpdatingCouponScheme = true;
    CouponManagementService.createCouponScheme({
      name: value.Name,
      ERPId: value.ERPId,
      description: value.Description,
      startdate: value.StartDate,
      enddate: value.EndDate,
      coupondistribution: CouponDistribution.FixedNumber,
      totalnumbercoupons: value.TotalNumberCoupons,
      couponwisedistribution: value.CouponwiseDistribution,
      slabs: value.Slabs,
    })
      .then((response: any) => {
        this.snackbarColor = "success";
        this.snackbar = true;
        this.snackBarMessage = "Changes Saved Successfully";
        this.creatingOrUpdatingCouponScheme = false;
        this.clearForm();
        this.refreshCouponList();
        this.SchemeList();
      })
      .catch((error: { response: { status: number; data: string } }) => {
        this.creatingOrUpdatingCouponScheme = false;
        if (error.response.status == 400) {
          this.snackbarColor = "red";
          this.snackbar = true;
          this.snackBarMessage = error.response.data;
        }
      });
  }
 public SchemeList(): void {
    this.$router.push({ name: "SchemeList" });
  }
  public async getCoupon(): Promise<boolean> {
    return await new Promise<boolean>((res) => {
      CouponManagementService.GetGift()
        .then((response) => {
          var result = response.data;
          console.log(result);
          this.GiftsAvailable = result;
          res(true);
        })
        .catch((error): any => {
          console.log(error);
        });
    });
  }

  public closeEndDateMenu() {
    // @ts-ignore
    this.$refs["endDate"].closeMenu();
  }
}
